import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-95d47678"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "count-page"
};
const _hoisted_2 = {
  class: "ws_table"
};
import { onMounted, ref, watch, onUnmounted } from 'vue';
import { queryDistributorProfitTotalPage } from '@/api';
import moment from 'moment';
import { CODE_ENUM, formatFormTime, transformNumber } from 'common';
export default {
  __name: 'index',
  setup(__props) {
    const advancedRef = ref();
    const tableData = ref([]);
    const formInline = ref({
      productKey: ''
    });
    const pageParams = ref({
      curPage: 1,
      pageSize: 10
    });
    const total = ref(0);
    const handleSizeChange = () => {
      pageParams.value.curPage = 1;
      fetchList();
    };
    const handleCurrentChange = () => {
      fetchList();
    };
    const col = [{
      prop: 'distributorName',
      label: '分销商名称'
    }, {
      prop: 'distributorLevel',
      label: '等级'
    }, {
      prop: 'totalAmount',
      label: '累计分润金额(元)',
      className: 'align-right',
      formatter: ({
        totalAmount
      }) => {
        return transformNumber(totalAmount);
      }
    }, {
      prop: 'settledAmount',
      label: '已结算金额((元)',
      className: 'align-right',
      formatter: ({
        settledAmount
      }) => {
        return transformNumber(settledAmount);
      }
    }, {
      prop: 'unsettledAmount',
      label: '未结算金额(元)',
      className: 'align-right',
      formatter: ({
        unsettledAmount
      }) => {
        return transformNumber(unsettledAmount);
      }
    }];
    const tableLoading = ref(false);
    const fetchList = async () => {
      tableLoading.value = true;
      const res = await queryDistributorProfitTotalPage({
        ...pageParams.value
      }).finally(() => {
        tableLoading.value = false;
      });
      if ((res === null || res === void 0 ? void 0 : res.code) === CODE_ENUM.SUCCESS) {
        var _res$data, _res$data2;
        tableData.value = (res === null || res === void 0 || (_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.records) || [];
        total.value = (res === null || res === void 0 || (_res$data2 = res.data) === null || _res$data2 === void 0 ? void 0 : _res$data2.total) || 0;
      }
    };
    fetchList();
    return (_ctx, _cache) => {
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_empty = _resolveComponent("el-empty");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_withDirectives((_openBlock(), _createBlock(_component_el_table, {
        class: "table",
        data: tableData.value
      }, {
        empty: _withCtx(() => [_createVNode(_component_el_empty, {
          description: "暂无数据"
        })]),
        default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(col, (item, index) => {
          return _createVNode(_component_el_table_column, _mergeProps({
            key: index,
            ref_for: true
          }, {
            ...item
          }, {
            "show-overflow-tooltip": ""
          }), null, 16);
        }), 64))]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, tableLoading.value]]), _createVNode(_component_el_pagination, {
        class: "ws_mt50",
        "current-page": pageParams.value.curPage,
        "onUpdate:currentPage": _cache[0] || (_cache[0] = $event => pageParams.value.curPage = $event),
        "page-size": pageParams.value.pageSize,
        "onUpdate:pageSize": _cache[1] || (_cache[1] = $event => pageParams.value.pageSize = $event),
        "page-sizes": _ctx.PAGE_SIZES,
        layout: "->, total, sizes, prev, pager, next, jumper ",
        total: total.value,
        onSizeChange: handleSizeChange,
        onCurrentChange: handleCurrentChange
      }, null, 8, ["current-page", "page-size", "page-sizes", "total"])])]);
    };
  }
};